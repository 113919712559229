
.bookforms-textfields{
    margin-top: 0.2rem !important;
}

.genre-dropdown{
    line-height: 1.99;
}

.main-title {
    font-family: "Merriweather";
    font-weight: 900;
    font-size: 80px;
}

.sub-title {
    font-family: "Merriweather";
    font-size: 40px;
    font-weight: 520;
}

.body-grey {
    font-family: "Merriweather";
    font-size: 10px;
    color: rgb(131, 128, 128);
    opacity: 0.85;
}

.search-button {
    padding-top: 5px;
    background-color: rgb(0, 33, 65) !important;
    border-color: black !important;
}

