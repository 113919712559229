

.app{
    max-width: 1200px;
    width: 100%;
    margin: 100px auto;
    box-shadow: 0 0 5px #ccc;
  }

  .box .row span{
    font-size: 16px !important;
  }

 .box .row .price {
    font-size: 25px !important;
    font-weight: 550;
    font-family: 'Roboto Mono', monospace;
  }

  .details{
    display: flex;
    background-color: rgb(255, 255, 255);
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 30px 0;
    padding-bottom: 0px;
  }
  .details .big-img{
    max-width: 500px;
    min-width: 290px;
    overflow: hidden;
    padding-top: 30px;
    margin: 25px;
  }
  .big-img img{
    width: 100%;
    height: 100%;
    max-height: 400px;
    display: block;
    object-fit: cover;
  }
  
  .details .box{
    max-width: 500px;
    min-width: 290px;
    margin: 25px;
  }
  .box .row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .box .row h2{
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .box .row span{
    color: rgb(0, 33, 65);
    font-size: 30px;
  }
  .box .colors button{
    width: 30px;
    height: 30px;
    border: 1px solid #333;
    outline: none;
    margin-right: 5px;
    cursor: pointer;
  }
  .box p{
    line-height: 1.5;
    margin: 15px 0;
    
  }
  .thumb{
    width: 100%;
    height: 100px;
    display: flex;
    cursor: pointer;
    margin: 10px 0;
  }
  .thumb img{
    width: 90px;
    height: 100%;
    display: block;
    object-fit: cover;
    border: 1px solid #ddd;
    margin-right: 5px;
    opacity: 0.7;
    border-radius: 5px;
  }
  .thumb img.active{
    opacity: 1;
    border: 1px solid lightseagreen;
  }
  .box .purchase-listing-button{
    /* background: #333; */
    background: rgb(0, 33, 65);
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px 25px;
    margin-top: 50px;
  }

  .delete-listing-button {
    /* background: #333; */
    background: rgb(0, 33, 65);
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px 25px;
    margin-top: 50px;
    margin-left: 80px;
  }

  
  @media (max-width: 500px){
    .thumb{
      height: 50px;
    }
    .thumb img{
      width: 50px;
    }


  }
.body-grey {
  font-family: "Merriweather";
  font-size: 15px;
  color: rgb(131, 128, 128);
  opacity: 0.85;
}
.book-info {
  padding-top: 3px;
}
  